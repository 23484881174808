var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "malfunction_guide_detail_page" }, [
    _c("div", { staticClass: "main_area" }, [
      _c("p", { staticClass: "title" }, [_vm._v("判定标准")]),
      _c("p", { staticClass: "content" }, [
        _vm._v(_vm._s(_vm.detail.judgeStandard)),
      ]),
      _c("p", { staticClass: "title" }, [_vm._v("处理方法")]),
      _c("p", { staticClass: "content" }, [
        _vm._v(_vm._s(_vm.detail.handlingMethod)),
      ]),
      _c("p", { staticClass: "title" }, [_vm._v("排除验证方法")]),
      _c("p", { staticClass: "content" }, [
        _vm._v(_vm._s(_vm.detail.verifyMethod)),
      ]),
      _c("p", { staticClass: "title_show_pic" }, [_vm._v("示例图片")]),
      _vm.detail.attachments &&
      _vm.detail.attachments.length > 0 &&
      (_vm.isOffline || _vm.showImgAfterLoad)
        ? _c(
            "div",
            _vm._l(_vm.detail.attachments, function (list, index) {
              return _c("van-image", {
                key: index,
                staticClass: "photo",
                attrs: {
                  width: "40",
                  height: "40",
                  radius: "4",
                  src: _vm.isOffline
                    ? list.url
                    : _vm.offlineImageBase64[list.url],
                },
                on: {
                  click: function ($event) {
                    return _vm.imgPreview(index, _vm.detail.attachments)
                  },
                },
              })
            }),
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }