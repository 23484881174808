<template>
  <div class="cascader_page">
    <!-- 导航栏 -->
    <my-nav style="height:48.6px" :title="title" :self-back="true" @back="goBack">
      <div slot="right" v-if="selectType==='checkbox' && checkBoxShow" class="multi-wrapper" @click="selectAll">
        <i class="multi-icon" :class="`multi-${checkBoxShowType}`"></i>
        <span class="title">全选</span>
      </div>
    </my-nav>
    <div v-if="!leafCompShow" class="search_wrapper py-6px px-16px">
      <van-search v-model="searchValue" placeholder="请输入关键字" @search="handleSearch" @clear="handleClearSearch"  />
    </div>
    <div v-if="!leafCompShow" class="main_area">
      <div class="select_area">
        <section>
          <div class="select_area" v-if="breadCrumbsList[breadCrumbsList.length-1]&&!searchList.children">
            <van-cell :title="node[codeNameProps]" clickable center
                      v-for="(node, index) in breadCrumbsList[breadCrumbsList.length-1][childrenProps]"
                      :key="index" @click="selectItem(node, index)">
              <template #right-icon>
                <van-icon v-if="node[childrenProps]" name="arrow" />
                <div
                  v-else-if="(selectType==='radio' || selectType==='checkbox') && selectCodes.indexOf(node[codeProps]) > -1"
                  class="select"></div>
              </template>
            </van-cell>
          </div>
          <div class="select_area" v-if="searchList.children">
            <van-cell :title="node[codeNameProps]" clickable center
                      v-for="(node, index) in searchList[childrenProps]"
                      :key="index" @click="selectItem(node, index)">
              <template #right-icon>
                <van-icon v-if="node[childrenProps]" name="arrow" />
                <div
                  v-else-if="(selectType==='radio' || selectType==='checkbox') && selectCodes.indexOf(node[codeProps]) > -1"
                  class="select"></div>
              </template>
            </van-cell>
          </div>
        </section>
      </div>
      <div class="bread_crumbs">
        <div class="bread_item" v-for="(item, index) in breadCrumbsList" :key="item[codeProps]"
          @click="breadClick(item, index)">
          <span class="bread_text">{{item[codeNameProps]}}</span>
          <van-icon v-if="index < breadCrumbsList.length - 1" class="bread_arrow" name="arrow" />
        </div>
      </div>
      <div v-if="selectType==='radio' || selectType==='checkbox'" class="submit-wrapper"
        @click="submit()">
        <p v-if="selectType==='checkbox'" class="count">({{ selectCodes.length }})</p>
        <p class="text">确定</p>
      </div>
    </div>
    <component v-else :is="leafCompName" :detail="currentNode" />
  </div>
</template>

<script>
import MalfunctionGuideDetailComp from './MalfunctionGuideDetailComp'
export default {
  name: 'Cascader',
  props: {
    title: {
      type: String,
      default: '级联选择'
    },
    selectType: {
      type: String,
      default: undefined // radio checkbox
    },
    initList: {
      type: Array,
      default: () => []
    },
    cbSelect: {
      type: Function,
      default: undefined
    },
    codeProps: {
      type: String,
      default: 'code'
    },
    codeNameProps: {
      type: String,
      default: 'codeName'
    },
    childrenProps: {
      type: String,
      default: 'children'
    },
    rootName: {
      type: String,
      default: '根节点'
    },
    cbGoBack: {
      type: Function,
      default: undefined
    },
    leafCompName: {
      type: String,
      default: undefined
    },
    required: {
      type: Boolean,
      default: false
    },
    isAssembly: { // 是否作为选择器 false为选择器
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      searchValue: undefined,
      breadCrumbsList: [],
      currentNode: {
        children: []
      },
      searchList: {
        children: undefined
      },
      selectCodes: [],
      selectDetails: [],
      checkedAll: false,
      leafCompShow: false,
      searchInterval: undefined
    }
  },
  computed: {
    checkBoxShowType () {
      let show = false
      if (this.selectType !== 'checkbox' || this.breadCrumbsList.length === 0) {
        return show
      }
      const currentBredcrumb = this.breadCrumbsList[this.breadCrumbsList.length - 1]
      let count = currentBredcrumb[this.childrenProps].length
      let currentNodeCount = count
      currentBredcrumb[this.childrenProps] && currentBredcrumb[this.childrenProps].forEach(item => {
        if (this.selectCodes.indexOf(item[this.codeProps]) === -1) {
          currentNodeCount--
        }
      })
      if (currentNodeCount === 0) {
        return 'default'
      } else if (currentNodeCount < count) {
        return 'check'
      } else {
        return 'all'
      }
    },
    checkBoxShow () {
      const currentBredcrumb = this.breadCrumbsList[this.breadCrumbsList.length - 1]
      let checkBoxShow = true
      currentBredcrumb && currentBredcrumb[this.childrenProps] && currentBredcrumb[this.childrenProps].forEach(item => {
        if (item[this.childrenProps]) {
          checkBoxShow = false
        }
      })
      return checkBoxShow
    }
  },
  watch: {
    initList (newVal) {
      this.$set(this.currentNode, this.childrenProps, newVal || [])
      this.$set(this.currentNode, this.codeNameProps, this.rootName)
      if (this.breadCrumbsList.length === 0) {
        this.breadCrumbsList.push(this.currentNode)
      }
    }
    // searchValue (searchValue) {
    //   if (this.searchInterval) {
    //     clearInterval(this.searchInterval)
    //   }
    //   this.searchInterval = setTimeout(() => {
    //     this.handleSearch(searchValue)
    //   }, 500)
    // }
  },
  created () { // 解决popup弹窗中无法滑动问题
    window.addEventListener('touchmove', this._touchmoveEvent, true)
  },
  beforeDestroy () {
    window.removeEventListener('touchmove', this._touchmoveEvent, true)
  },
  methods: {
    selectItem (item, index) {
      this.currentNode = item
      let dynamicAdd = false
      if (this.cbSelect) {
        dynamicAdd = this.cbSelect({ ...item, index }, this.breadCrumbsList)
      }
      if (item[this.childrenProps]) { // 有children选择下一级，leaf不加入面包屑
        this.breadCrumbsList.push(item)
      } else {
        if (dynamicAdd instanceof Promise) {
          dynamicAdd.then(this.dynamicAddHandler)
        } else {
          this.dynamicAddHandler(dynamicAdd)
        }
      }
    },
    dynamicAddHandler (dynamicAddFlag) {
      if (!dynamicAddFlag) {
        // 判断是否是叶子节点
        if ((this.selectType === 'radio' || this.selectType === 'checkbox')) {
          this.addSelectNode()
        } else if (this.leafCompName) {
          // 显示动态组件
          this.leafCompShow = true
        }
      } else {
        this.breadCrumbsList.push(this.currentNode)
      }
    },
    addSelectNode (addNode) {
      let item = addNode || this.currentNode
      const index = this.selectCodes.indexOf(item[this.codeProps])
      if (this.selectType === 'checkbox') {
        if (index === -1) {
          this.selectCodes.push(item[this.codeProps])
          this.selectDetails.push(item)
        } else {
          this.selectCodes.splice(index, 1)
          this.selectDetails.splice(index, 1)
        }
      } else if (this.selectType === 'radio') {
        if (index === -1) {
          this.selectCodes.splice(0, 1, item[this.codeProps])
          this.selectDetails.splice(0, 1, item)
        } else {
          this.selectCodes.splice(index, 1)
          this.selectDetails.splice(index, 1)
        }
      }
    },
    breadClick (item, index) {
      this.breadCrumbsList = this.breadCrumbsList.splice(0, index + 1)
      this.currentNode = item
    },
    selectAll () {
      const currentBredcrumb = this.breadCrumbsList[this.breadCrumbsList.length - 1]
      const list = this.checkBoxShowType === 'check' ? currentBredcrumb[this.childrenProps].reduce((arr, item) => {
        if (this.selectCodes.indexOf(item[this.codeProps]) === -1) {
          arr.push(item)
        }
        return arr
      }, []) : currentBredcrumb[this.childrenProps]
      list.forEach(item => {
        if (!item[this.childrenProps]) {
          this.addSelectNode(item)
        }
      })
    },
    submit () {
      if (this.selectCodes && this.selectCodes.length === 0 && this.required) {
        this.$toast('未选择')
        return
      }
      this.$emit('confirm', this.lodash.cloneDeep(this.selectCodes), this.lodash.cloneDeep(this.selectDetails))
      if (!this.isAssembly) { // 当不作为选择器的时候
        this.clearSelectData()
        this.goBack()
      }
    },
    goBack () {
      // 面包屑有数据则返回上一级，没有则返回上一页
      // const length = this.breadCrumbsList.length
      // if (length > 0) {
      //   this.currentNode = this.breadCrumbsList[length - 1]
      //   this.breadCrumbsList = this.breadCrumbsList.splice(length - 1, 1)
      // }
      if (this.leafCompShow) {
        this.leafCompShow = false
        const length = this.breadCrumbsList.length
        if (length > 1) {
          this.currentNode = this.breadCrumbsList[length - 1]
        }
        return
      }
      if (this.cbGoBack) {
        this.cbGoBack()
      }
      this.clearSelectData()
    },
    setSearchList (list) {
      this.searchList.children = list
    },
    handleSearch () { // 搜索方法
      if (this.searchValue) {
        this.$emit('search', { searchValue: this.searchValue, breadCrumbsList: this.breadCrumbsList })
      } else {
        this.searchList.children = undefined
      }
    },
    handleClearSearch () {
      this.searchList.children = undefined
    },
    clearSelectData () {
      this.breadCrumbsList = []
      this.selectCodes = []
      this.selectDetails = []
      this.currentNode = {}
    },
    _touchmoveEvent (e) {
      const target = e.target
      if (target && (target.tagName === 'TEXTAREA' || target.tagName === 'DIV' || target.tagName === 'P' || target.tagName === 'I' || target.tagName === 'IMG' || target.tagName === 'INPUT')) {
        e.stopPropagation()
      }
    }
  },
  components: {
    MalfunctionGuideDetailComp  // eslint-disable-line
  }
}
</script>

<style lang="less" scoped>
.icon-back {
  height: 24px;
  width: 24px;
  background: url("../asset/icon_back_black@2x.png") no-repeat;
  background-size: cover;
}

.search_wrapper {
  ::v-deep {
    .van-search__content {
      background-color: #f5f6f6 !important;
    }
    .van-search {
      padding: 0;
    }
  }
}
.cascader_page {
  ::v-deep .van-icon-success {
    font-size: 15px !important;
    color: white !important;
  }
}

.cascader_page {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;

  .main_area {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    .select_area {
      flex: 1;
      overflow-y: auto;

      .select {
        background: url("../asset/detail-check.png") no-repeat;
        background-size: 100% 100%;
        width: 20px;
        height: 20px;
      }
    }

    .bread_crumbs {
      display: flex;
      flex-wrap: wrap;
      min-height: 70px;

      .bread_item {
        display: flex;
        align-items: center;

        .bread_text {
          font-size: 16px;
          padding: 0 10px;
          color: #3366fe;
        }

        .bread_arrow {
          font-size: 12px;
          color: #c5c5c5;
        }
      }
    }
    .submit-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 64px;
      height: 64px;
      background: linear-gradient(90deg, #62a9ff 0%, #3370fe 100%);
      box-shadow: 0px 2px 6px 0px rgba(51, 102, 254, 0.4);
      border-radius: 50%;
      text-align: center;
      overflow: hidden;
      position: fixed;
      right: 16px;
      bottom: 76px;

      .count {
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;
        line-height: 1;
        margin-top: 13px;
      }

      .text {
        font-size: 16px;
        font-weight: 400;
        color: #ffffff;
        line-height: 1;
        margin-top: 5px;
      }
    }
  }
}
.multi-wrapper {
  display: flex;
  align-items: center;
  .multi-icon {
    width: 24px;
    height: 24px;
  }
  .multi-default {
    background: url("~@/asset/multi-default.png") no-repeat;
    background-size: 100% 100%;
  }
  .multi-check {
    background: url("~@/asset/multi-check.png") no-repeat;
    background-size: 100% 100%;
  }
  .multi-all {
    background: url("~@/asset/multi-all.png") no-repeat;
    background-size: 100% 100%;
  }
  .title {
    font-size: 16px;
    font-weight: 400;
    color: #222222;
  }
}
</style>
