<template>
  <div class="malfunction_guide_detail_page">
    <div class="main_area">
      <p class="title">判定标准</p>
      <p class="content">{{detail.judgeStandard}}</p>
      <p class="title">处理方法</p>
      <p class="content">{{detail.handlingMethod}}</p>
      <p class="title">排除验证方法</p>
      <p class="content">{{detail.verifyMethod}}</p>
      <p class="title_show_pic">示例图片</p>
      <div v-if="detail.attachments && detail.attachments.length>0 && (isOffline || showImgAfterLoad)">
          <van-image width="40" height="40" radius="4" class="photo"
                   v-for="(list, index) of detail.attachments" @click="imgPreview(index,detail.attachments)"
                   :key="index" :src="isOffline ? list.url : offlineImageBase64[list.url]" />
      </div>
    </div>
  </div>
</template>

<script>
import { ImagePreview } from 'vant'
import { cloneDeep } from 'lodash'
import {
  OssConversionBase64
} from '@/plugins/offlineMixin/utils/offlineImgTool'
export default {
  name: 'MalfunctionGuideDetailComp',
  data () {
    return {
      listData: {},
      offlineImageBase64: {},
      showImgAfterLoad: false
    }
  },
  props: {
    detail: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    isOffline () {
      return this.$store.getters.getIsOffline
    }
  },
  methods: {
    /* 图片查看器 */
    imgPreview (index, attachments) {
      const imagesGroup = []
      attachments.forEach((item) => {
        imagesGroup.push(this.isOffline ? item.url : this.offlineImageBase64[item.url])
      })
      ImagePreview({
        images: imagesGroup,
        startPosition: index
      })
    }
  },
  async mounted () {
    this.listData = cloneDeep(this.detail)
    // 处理多选回显数据库值得处理
    if (
      this.listData.attachments &&
        this.listData.attachments.length > 0 &&
        !this.isOffline
    ) {
      for (const urlItem of this.listData.attachments) {
        this.offlineImageBase64[urlItem.url] = await OssConversionBase64(urlItem.url)
      }
      this.showImgAfterLoad = true
    }
  }
}
</script>

<style lang="less" scoped>
  .malfunction_guide_detail_page {
    padding: 15px 16px;
    flex: 1;

    p {
      margin: 0;
      padding: 0;
    }

    .main_area {
      .title {
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        color: black;
      }
      .title_show_pic {
        font-size: 16px;
        line-height: 24px;
        color: #222222;
      }
      .content {
        margin: 8px 0 30px 0;
        font-size: 14px;
        line-height: 20px;
        color: #8B8F9A;
      }
    }
  }
</style>
